/** 审核状态枚举 */
export const AUDIT_STATUS_ENUM = {
  0: {
    value: 0,
    label: "待审核",
  },
  1: {
    value: 1,
    label: "取消审核",
  },
  2: {
    value: 2,
    label: "审核通过",
  },
  3: {
    value: 3,
    label: "审核驳回",
  },
};

/** 上架状态枚举 */
export const LISTING_STATUS_ENUM = {
  2: {
    value: 2,
    label: "已上架",
  },
  3: {
    value: 3,
    label: "已下架",
  },
};
