<template>
  <div class="sale-wrapper bj">
    <div class="title">
      <div class="seach">
        <div class="inputs">
          <el-select
            v-model="from1.audit_status"
            clearable
            placeholder="审核状态"
          >
            <el-option
              v-for="item of auditList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select v-model="from1.state" clearable placeholder="上架状态">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item of listingList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.promotion_tag_name"
            placeholder="输入标签"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.category_tag_name"
            placeholder="输入品类/品牌"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.logistic_business_id"
            clearable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>

        <div class="inputs">
          <el-date-picker
            v-model="from1.create_time"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择创建日期"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.product_name"
            placeholder="输入商品名称"
            clearable
          ></el-input>
        </div>

        <div class="inputs">
          <el-input
            v-model="from1.sku_code"
            placeholder="输入货号"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.supplier_name"
            placeholder="输入供应商名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.buyer_name"
            placeholder="输入采购员名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-button type="primary" icon="el-icon-search" @click="search">
            查询
          </el-button>
          <el-button
            type="warning"
            icon="el-icon-upload2"
            @click="onHandleExport"
            :disabled="!tableData.length || disabledExport"
            >导出
          </el-button>
        </div>
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
        <!-- <el-button @click="onHandleExport" type="warning" icon="el-icon-upload2"
          >导出</el-button
        > -->
      </div>
    </div>
    <div class="auto-table-flex">
      <table-list
        v-loading="loading"
        :tableData="tableData"
        @submit-form="submitForm"
      ></table-list>
    </div>
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="50"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { AUDIT_STATUS_ENUM, LISTING_STATUS_ENUM } from "./utils/enum/index.js";
import TableList from "./modules/table-list/index.vue";
import url from "url";
import { BASE } from "@/api";
import { postProductListExport } from "@/api/export/center.js";

export default {
  name: "warehouseGoods",
  components: { TableList },
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      auditList: Object.values(AUDIT_STATUS_ENUM), // 审核状态
      listingList: Object.values(LISTING_STATUS_ENUM), // 上架状态
      from: {
        create_time: "",
        product_name: "",
        logistic_business_id: "",
        supplier_name: "",
        buyer_name: "",
        sku_code: "",
        category_tag_name: "", // 品类/品牌
        promotion_tag_name: "", // 标签
        state: "", // 上架状态
        audit_status: "", // 审核状态
        page: 1,
        pageSize: 50,
        is_tdc_owner: 1, // 仓主品
      },
      from1: {
        create_time: "",
        product_name: "",
        logistic_business_id: "",
        supplier_name: "",
        buyer_name: "",
        sku_code: "",
        category_tag_name: "", // 品类/品牌
        promotion_tag_name: "", // 标签
        state: "", // 上架状态
        audit_status: "", // 审核状态
        page: 1,
        pageSize: 50,
        is_tdc_owner: 1, // 仓主品
      },
      tableData: [],
      options: [],
      currentPage: 1,
      total: 0,
      list: [],
      disabledExport: false, // 导出按钮
    };
  },
  created() {
    this.hqlist();
    this.getBusinessList();
  },
  watch: {},
  methods: {
    /**
     * 导出
     */
    async onHandleExport() {
      this.disabledExport = true;
      try {
        const query = {
          ...this.from,
          token: sessionStorage.getItem("token"),
          is_tdc_owner: 1,
        };
        delete query.page;
        delete query.pageSize;
        await postProductListExport(query);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax postPurchaseSettleExport err", err);
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    // 获取集配中心
    getBusinessList() {
      this.$api.general.businessList().then((res) => {
        this.options = res.data;
      });
    },
    // 搜索查询
    search() {
      this.from.page = 1;
      this.currentPage = 1;
      this.from = this.from1;
      this.hqlist();
    },
    // 操作后 刷新列表
    submitForm() {
      this.from.page = 1;
      this.currentPage = 1;
      this.from = this.from1;
      this.hqlist();
    },
    // 重置列表
    Refresh() {
      this.from = {
        create_time: "",
        product_name: "",
        logistic_business_id: "",
        supplier_name: "",
        buyer_name: "",
        sku_code: "",
        state: "",
        audit_status: "",
        page: 1,
        pageSize: 50,
        is_tdc_owner: 1, // 仓主品
      };
      this.from1 = {
        create_time: "",
        product_name: "",
        logistic_business_id: "",
        supplier_name: "",
        buyer_name: "",
        sku_code: "",
        state: "",
        audit_status: "",
        page: 1,
        pageSize: 50,
        is_tdc_owner: 1, // 仓主品
      };
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
    hqlist() {
      this.loading = true;
      this.$api.general.productList(this.from).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.sale-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;

  .goods-image {
    width: 50px;
    height: 50px;
  }

  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    // display: flex;

    .seach {
      // flex: 1;
      display: flex;
      flex-wrap: wrap;
      // justify-content: flex-end;

      .inputs {
        // width: 207px;
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
      margin-bottom: 8px;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
