<template>
  <div class="table-cnt">
    <el-table
      :data="tableData"
      height="100%"
      :border="true"
      :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
    >
      <el-table-column type="index" width="100px" align="center" label="序号">
      </el-table-column>
      <el-table-column
        prop="logistic_business_name"
        align="center"
        label="集配中心"
      >
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.logistic_business_name) }}
        </template>
      </el-table-column>
      <el-table-column prop="fullname" align="center" label="商品主图">
        <template slot-scope="scope">
          <img :src="scope.row.kv" alt="" class="goods-image" />
        </template>
      </el-table-column>
      <el-table-column prop="fullname" align="center" label="商品名称">
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.title) }}
        </template>
      </el-table-column>
      <el-table-column prop="name" align="center" label="货号">
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.sku_code) }}
        </template>
      </el-table-column>
      <el-table-column prop="name" align="center" label="等级">
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.grade) }}级
        </template>
      </el-table-column>
      <el-table-column prop="name" align="center" label="毛重">
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.gross_weight) }}斤
        </template>
      </el-table-column>
      <el-table-column prop="name" align="center" label="毛单价">
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.gross_weight_price) }}元/斤
        </template>
      </el-table-column>
      <el-table-column prop="name" align="center" label="净重">
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.weight) }}斤
        </template>
      </el-table-column>
      <el-table-column prop="name" align="center" label="净单价">
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.weight_price) }}元/斤
        </template>
      </el-table-column>
      <el-table-column prop="name" align="center" label="开市价">
        <template slot-scope="scope">
          {{ tool.toDecimal2((scope.row.price * 10000) / 100 / 10000) }}元/斤
        </template>
      </el-table-column>
      <el-table-column prop="name" align="center" label="供货商">
        <template slot-scope="scope">
          <span v-if="scope.row.supplier">{{
            $empty.empty(scope.row.supplier.title)
          }}</span>
          <span v-else>—</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" align="center" label="采购员">
        <template slot-scope="scope">
          <span v-if="scope.row.buyer">{{
            $empty.empty(scope.row.buyer.fullname)
          }}</span>
          <span v-else>—</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" align="center" label="创建时间">
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="name" align="center" label="库存数量">
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.stock) }}
        </template>
      </el-table-column>
      <el-table-column prop="audit_status_txt" align="center" label="审核状态">
      </el-table-column>
      <el-table-column prop="state_txt" align="center" label="上架状态">
      </el-table-column>
      <el-table-column prop="sales" align="center" label="销量">
      </el-table-column>
      <el-table-column
        label="操作"
        fixed="right"
        align="center"
        min-width="130"
      >
        <template slot-scope="scope">
          <!-- 待审核状态 展示 start -->
          <el-button type="text" @click="onHandelDetails(scope.row)"
            >查看详情</el-button
          >
          <el-button
            type="text"
            v-if="scope.row.audit_status == AUDIT_STATUS_ENUM['0'].value"
            @click="onHandleStatus(scope.row, AUDIT_STATUS_ENUM['2'].value)"
            >审核</el-button
          >
          <el-button
            type="text"
            v-if="scope.row.audit_status == AUDIT_STATUS_ENUM['0'].value"
            @click="onHandleStatus(scope.row, AUDIT_STATUS_ENUM['3'].value)"
            >拒绝</el-button
          >
          <!-- 待审核状态 展示 end -->
        </template>
      </el-table-column>
    </el-table>
    <div v-if="detailsVisible" class="dialogbj">
      <div style="text-align: right">
        <i class="el-icon-circle-close" @click="detailsVisible = false"></i>
      </div>

      <goods-details
        :loading="detailsLoading"
        :details="goodsDetail"
      ></goods-details>
    </div>
  </div>
</template>

<script>
import { postOwnerProductAudit } from "@/api/generalControl/warehouse";
import { AUDIT_STATUS_ENUM } from "../../utils/enum/index.js";
import GoodsDetails from "@/views/general/modules/goods-change-dialog/modules/goods-details.vue";
export default {
  name: "TableList",
  props: ["tableData"],
  components: { GoodsDetails },

  data() {
    return {
      AUDIT_STATUS_ENUM,
      detailsVisible: false,
      detailsLoading: false,
      goodsDetail: {}, // 商品详情
    };
  },
  methods: {
    /**
     * 展示详情
     */
    onHandelDetails(row) {
      this.detailsVisible = true;
      this.detailsLoading = true;
      this.goodsDetail = {
        ...row,
        price: this.tool.toDecimal2((row.price * 10000) / 100 / 10000),
      };
      // 模拟loading
      setTimeout(() => {
        this.detailsLoading = false;
      }, 500);
    },

    /**
     * 审核 拒绝
     */
    onHandleStatus(row, type) {
      this.$confirm(
        type == AUDIT_STATUS_ENUM["2"].value ? `确定审核么？` : `确定拒绝么？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          try {
            const res = await postOwnerProductAudit({
              product_id: row.id,
              status: type,
            });
            this.$message({
              type: "success",
              message:
                type == AUDIT_STATUS_ENUM["2"].value ? "审核成功" : "拒绝成功",
            });
            this.$emit("submit-form");
          } catch (error) {
            console.log(error, "postOwnerProductAudit");
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message:
              type == AUDIT_STATUS_ENUM["2"].value ? "取消审核" : "取消拒绝",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.table-cnt {
  height: 100%;
  .dialogbj {
    // text-align: right;
    i {
      font-size: 30px;
    }
    width: 375px;
    height: 820px;
    position: fixed;
    background-color: #fff;
    top: 50%;
    left: 50%;
    // background-color: #000;

    transform: translateX(-50%) translateY(-50%);
  }
  .goods-image {
    width: 50px;
    height: 50px;
  }
}
</style>
